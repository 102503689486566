.auth-layout-wrap {
	padding-top: 120px;
	min-height: 100vh;

	.auth-content {
		max-width: 660px;
		margin: auto;
		width: 100%;

		h1 {
			color: #00455a;
		}

		.ask-for-help {
			margin-top: 32px;
			font-size: 14px;
			line-height: 1.43;
			color: #a6aaaf;
			text-align: center;

			.phone-number {
				font-size: 18px;
				font-weight: bold;
				line-height: 1.56;
				color: #16b097;
				display: flex;
				align-items: center;
				justify-content: center;

				.mdi {
					font-size: 24px;
					margin-right: 8px;
				}
			}
		}
	}

	.logo {
		display: block;
		margin: 0 auto;
		margin-bottom: 40px;
	}

	.card {
		padding-top: 40px;

		p {
			color: #a7a9ab;
			text-align: center;
			font-size: 14px;
		}

		.secondary-card-row {
			border-top: 2px solid #d9d9d9;
			padding-top: 32px;
			margin-top: 3rem;
			font-size: 14px;

			p {
				color: #3f3f3f;
				margin-bottom: 0;
			}

			a {
				cursor: pointer;
			}
		}

		.mat-checkbox .mat-checkbox-label {
			text-wrap: wrap;
		}
	}

	.btn {
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.29;
		letter-spacing: normal;
		text-align: center;
		color: #ffffff;
	}

	.link-forgot-password {
		font-size: 14px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.27;
		letter-spacing: normal;
		text-align: center;
		color: #a6aaaf;
		text-transform: uppercase;
	}
}

.layout-auth-bg {
	background-color: #f4f4f4;

	.auth-layout-wrap {
		padding-top: 32px;

		.auth-content {
			.card {
				margin-top: 90px;
			}
		}
	}
}

.auth-right {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.auth-logo {
	img {
		width: 100px;
		height: 100px;
	}
}

@media (min-width: 1024px) {
	.auth-layout-wrap {
		.auth-content {
			min-width: 660px;
		}
	}
}

@media (max-width: 767px) {
	.auth-layout-wrap {
		.auth-content {
			padding: 15px;
		}
	}

	.auth-right {
		padding: 80px 15px;
	}

	.layout-auth-bg .auth-layout-wrap .auth-content .card {
		margin-top: 32px;
		padding: 40px 16px 0 16px;
	}
}
// forms
.mat-form-field {
	width: 100%;

	.mat-form-field-label {
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.29;
		letter-spacing: normal;
		color: #3a4a5b;
	}

	&.mat-form-field-disabled {
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: #aeb0b3;

		.mat-form-field-label {
			font-size: 11px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.45;
			letter-spacing: normal;
			color: #aeb0b3;
		}
	}

	&.mat-form-field-invalid {
		margin-bottom: 10px !important;
	}

	.error {
		display: block;
		color: #ed4646;
	}
}

.mat-checkbox {
	.mat-checkbox-label {
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.56;
		letter-spacing: normal;
		color: #666666;
	}
}

.mat-tooltip {
	&.error {
		background: #ed4646;
		color: $white;
	}
}

label {
	font-size: 11px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.27;
	letter-spacing: normal;
	color: #3a4a5b;
}

.mat-radio-label-content {
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #262626;
}

.mat-error {
	font-size: 11px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.45;
	letter-spacing: normal;
	color: #c04848;
}

@mixin rotate($degrees) {
	-webkit-transform: rotate($degrees);
	-moz-transform: rotate($degrees);
	-ms-transform: rotate($degrees);
	-o-transform: rotate($degrees);
	transform: rotate($degrees);
	-ms-filter: "progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=#{cos($degrees)}, M12=#{-1*sin($degrees)}, M21=#{sin($degrees)}, M22=#{cos($degrees)})";
	filter: progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=#{cos($degrees)}, M12=#{-1*sin($degrees)}, M21=#{sin($degrees)}, M22=#{cos($degrees)});
}

#sidebar {
	transition: all 0.3s;
	position: sticky;
	z-index: 50;
	min-width: $sidebar-width;
	max-width: $sidebar-width;
	margin-left: -$sidebar-width;
	box-shadow: $sidebar-box-shadow;
	background: $sidebar-background;
	color: $sidebar-text-color;
	top: $topbar-height;
	height: calc(100vh - #{$topbar-height});

	ul.components {
		padding: 0;
	}
	ul {
		li {
			font-size: 16px;
			margin: 10px 0;

			> ul {
				margin-left: 10px;
				li {
					font-size: 14px;
				}
			}
			a {
				font-family: 'Lato', sans-serif;
				text-decoration: none;
				border-radius: $sidebar-border-radius;
				padding: 12px 24px;
				display: block;
				color: $sidebar-text-color;
				display: flex;
				align-items: center;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.43;
				letter-spacing: normal;

				&.dropdown-toggle::after {
					content: none;
				}

				i {
					margin-right: 18px;
					color: $sidebar-icon-color;
					font-size: 24px;

					&.icon-dropdown {
						font-size: 14px;
						position: absolute;
						right: 0;
					}
				}

				&:hover {
					font-weight: bold;
					background-color: $sidebar-background-hover;
					color: $sidebar-text-hover-color;
					i {
						color: $sidebar-icon-hover-color;
					}
				}

				&.active {
					font-weight: bold;
					color: $sidebar-text-active-color;
					background-color: $sidebar-background-active;
					i {
						color: $sidebar-icon-active-color;
					}
				}
			}

			&.dropdown-active {
				> a {
					i {
						&.icon-dropdown {
							transform: rotate(90deg);
						}
					}
				}
			}

			&.active {
				> a {
					font-weight: bold;
					color: $sidebar-text-active-color;
					background-color: $sidebar-background-active;
					i {
						color: $sidebar-icon-active-color;
						&.icon-dropdown {
							transform: rotate(90deg);
						}
					}
				}
			}
		}
	}

	&.active {
		margin-left: 0;
	}

  &.toggle-sidebar {
		min-width: 62px;
		max-width: 62px;

		ul {
			li {
				a {
					padding: 12px 6px;

					i {
						margin-right: 0;
					}
				}
			}
		}
	}

	a[data-toggle='collapse'] {
		position: relative;
	}

	.dropdown-toggle::after {
		display: block;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
	}

  ul.ul-toggle-sidebar {
		border-top: solid 1px #E8EAEA;
		a {
			color: #D4D8D8;
			i {
				color: #D4D8D8;
				font-weight: 300;
			}
			&:hover {
				font-weight: normal;
				color: #D4D8D8;
				i {
					color: var(--primary);
				}
			}
		}
	}
}

@media (max-width: 460px) {
  #sidebar {
    position: fixed;
    top: 0;
    height: 100vh;

  }
  #sidebar-container {
    padding-top: 60px !important;
  }
}

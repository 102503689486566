@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

@import './_variables.scss';
@import 'perfect-scrollbar/css/perfect-scrollbar.css';
@import '@swimlane/ngx-datatable/index.css';
@import '@swimlane/ngx-datatable/themes/material.css';
@import '@swimlane/ngx-datatable/assets/icons.css';
@import 'ngx-toastr/toastr.css';
@import 'highlight.js/styles/github.css';
@import 'ladda/dist/ladda.min.css';
@import 'dragula/dist/dragula.css';

$custom-file-text: ();
@import 'bootstrap/scss/bootstrap.scss';
@import 'bootstrap-rtl.scss';

@import 'globals/globals';

@import 'material/custom.scss';

.material-icons {
	font-family: 'Material Icons';
}

.mat-form-field {
	font-family: 'Roboto', sans-serif !important;
	letter-spacing: 0px;
}

button,
a,
.dropdown-item {
	outline: none !important;
}

.container-fluid {
	max-width: 1170px;
}

.line-clamp {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.content-btn-primary {
	border: 1px solid #00455a;
	color: #00455a;
	border-radius: 100px;

	&:not(:disabled):hover {
		color: #fff;
		background: #00455a;

		i {
			color: #fff;
		}
	}
}

.content-button {
	padding: 4px 16px;
}

.content-btn-danger {
	margin-right: 16px;
}

.btn-danger {
	border-radius: 100px;
}

.toggle-label {
	font-size: 16px;
	font-weight: 400;
}

.cdk-overlay-dark-backdrop {
	background: rgba(0, 0, 0, 0.5);
}

@media (max-width: 460px) {
	.mat-dialog-container {
		// max-width: 330px;
		color: #00455a;

		header {
			display: flex;
			justify-content: space-between;
			margin-bottom: 24px;

			.title {
				font-size: 20px;
				font-weight: bold;
				margin-bottom: 0;
			}

			.close {
				margin-left: auto;
				font-size: 24px;
				opacity: 1;
				text-shadow: none;
				top: -8px;
				right: -8px;
			}
		}

		.mat-dialog-content {
			max-width: 540px;
			color: #000;
		}

		.mat-dialog-actions {
			margin-top: 24px;
			margin-bottom: 0;
			justify-content: center;
		}
	}
}

.plan-modal {
	width: 800px;

	.mat-dialog-content {
		max-width: 100%;
	}
}

.cursor-pointer {
	cursor: pointer;
}

@for $i from 0 through 5 {
	.ml-#{$i} {
		margin-left: $i * 0.25rem !important;
	}

	.mr-#{$i} {
		margin-right: $i * 0.25rem !important;
	}

	.pl-#{$i} {
		padding-left: $i * 0.25rem !important;
	}

	.pr-#{$i} {
		padding-right: $i * 0.25rem !important;
	}
}

.mbc-icon-x-twitter {
	display: inline-block;
	-webkit-mask: url('/assets/images/icons/x-twitter.svg') no-repeat center center;
	mask: url('/assets/images/icons/x-twitter.svg') no-repeat center center;
	background-color: currentColor;
	width: 1em;
	height: 1em;
}

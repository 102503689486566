@import './theme';
@import './form';
@import './snackbar';

.mat-icon {
	color: #aeb0b3;
}

.mat-paginator-navigation-next,
.mat-paginator-navigation-previous {
	&:disabled {
		background: none !important;
	}
}

.mat-tab-label-active {
	color: $primary;
}

.mat-form-field-label-wrapper {
	top: -1.2em !important;
}

.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-datepicker-toggle-default-icon,
.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
	width: 1.5em !important;
	color: #aeb0b3;
}

.mat-radio-outer-circle {
	border-color: #dddd;
}

.mat-icon-search {
	font-size: 14px;
	color: #262626;
}

.mat-chip {
	border: 1px solid $branded1!important;
	border-radius: 4px;
	&.mat-standard-chip {
		background-color: transparent;
		color: $branded1;

		.mat-chip-remove {
			opacity: 1;
			color: $branded1;
		}
	}
}
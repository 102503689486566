// LAYOUT VARS
$topbar-height: 60px;
$topbar-height-mobile: 70px;

// sidebar background
$sidebar-width: 260px;
$sidebar-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
$sidebar-background: #ffffff;
$sidebar-background-hover: transparent;
$sidebar-background-active: #f2f2f2;
$sidebar-border-radius: 6px;

// sidebar color text
$sidebar-text-color: #a1a1a1;
$sidebar-text-active-color: #35383b;
$sidebar-text-hover-color: #35383b;

// sidebar color icon
$sidebar-icon-color: #d9d9d9;
$sidebar-icon-active-color: #00455A;
$sidebar-icon-hover-color: #00455A;

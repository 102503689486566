.pricing {
	.card {
		height: 100%;
		border: none;
		border-radius: 1rem;
		transition: all 0.2s;
		box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);

		.description {
			min-height: 60px;
			max-height: 60px;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.43;
			letter-spacing: normal;
			color: #666666;
		}

		hr {
			margin: 1.5rem 0;
		}
		ul li {
			margin-bottom: 1rem;
		}

		.text-muted {
			opacity: 0.7;
		}

		.btn {
			font-size: 80%;
			border-radius: 5rem;
			letter-spacing: 0.1rem;
			font-weight: bold;
			padding: 1rem;
			opacity: 0.7;
			transition: all 0.2s;
		}

		.card-title {
			margin: 0.5rem 0;
			font-size: 40px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.2;
			letter-spacing: normal;
			color: $branded5;
		}

		.card-price {
			font-size: 3rem;
			margin: 0;
			color: #000000;

			.period {
				font-size: 0.8rem;
			}
		}
	}
}

/* Hover Effects on Card */

@media (min-width: 992px) {
	.pricing {
		.card:hover {
			margin-top: -0.25rem;
			margin-bottom: 0.25rem;
			box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
		}
		.card:hover .btn {
			opacity: 1;
		}
	}
}

/**
PLAN SELECTED
*/

.card-select-payment {
	height: 114px;
	border-radius: 8px;
	border: solid 1px #d4d4d4;
	margin-right: 20px;
	padding: 28px 0px 0px 24px;

	&.selected {
		background-color: #f7f7f7;
	}
	.paymentImg {
		margin-left: 24px;
	}
}

.info-box-payment {
	margin-left: 30px;
	width: 300px;
	height: 230px;
	opacity: 0.9;
	border-radius: 8px;
	background-color: #e9e9ea;
	padding: 24px;

	.plan {
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: $dark1;
		margin-top: 7px;
	}

	.value {
		display: flex;

		.period {
			font-size: 18px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.56;
			letter-spacing: normal;
			color: $grey1;
			bottom: 0px;
			margin-top: 20px;
			margin-left: 6px;
		}

		.cifrao {
			font-size: 18px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.56;
			letter-spacing: normal;
			color: $grey1;
			margin-top: 10px;
			margin-right: 16px;
		}

		.money {
			font-size: 48px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.17;
			letter-spacing: normal;
			color: $dark1;
		}
	}

	.options {
		list-style: none;
		margin: 0;
		padding: 0;
		margin-top: 22px;

		li {
			display: flex;
			color: $dark3;
			font-size: 14px;
			align-items: center;
			i {
				color: $branded1;
				font-size: 16px;
			}
		}
	}
}

.card-img-input {
	position: absolute;
	right: 0;
	top: -3px;
	width: 31px;
	height: 24px;
}

.payment-success-message {
	font-size: 18px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.56;
	letter-spacing: normal;
	color: #666666;
}
